import React, { useEffect, useState } from "react";
import "./Search.css";
import { BASE_URL } from "../../utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { City } from "country-state-city";
import { toast } from "react-toastify";

const INIT = {
    specialities: "",
    state: "",
    city: "",
};

const Search = () => {
    const navigate = useNavigate();

    const [filter, setFilter] = useState(INIT);
    const [specialities, setSpecialities] = useState([]);

    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [speciality, setSpeciality] = useState("");
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);

    const handleOnChange = (e) => {
        setFilter({ ...filter, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        axios
            .get(BASE_URL + "api/speciality")
            .then((res) => {
                setSpecialities(res?.data?.specialities || []);
            })
            .catch((err) => console.log(err));
        axios
            .get(BASE_URL + "api/state")
            .then((res) => {
                setStateData(res?.data?.states || []);
            })
            .catch((err) => console.log(err));
    }, []);

    const handleState = (value) => {
        let filteredData = [];
        const data = City.getAllCities();
        const uniqueCities = new Set();

        for (let item of data) {
            if (
                value?.countryCode === item?.countryCode &&
                value?.isoCode === item?.stateCode
            ) {
                const cityName = item.name.replace(/,/g, "").trim();
                if (!uniqueCities.has(cityName)) {
                    uniqueCities.add(cityName);
                    filteredData.push({ ...item, name: cityName });
                }
            }
        }
        setCityData(filteredData);
    };

    const handleSearch = () => {
        const query = new URLSearchParams(filter).toString();
        if (filter?.city && filter?.specialities && filter?.state) {
            navigate(`/search?${query}`);
        } else {
            toast.error("All fields are required")
        }
    };

    return (
        <section className="sb-80 mt-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="title text-center">
                        <h2 className="mb-4 mb-lg-3">
                            Find Ayurved VaidyaNearYou
                        </h2>
                    </div>
                    <div className="border_input mx-lg-0 p-0 px-3 px-lg-0 position-relative">
                        <div className="background_box d-flex  flex-wrap align-items-center justify-content-start justify-content-xl-center">
                            <div className="col-12 col-lg-11 d-flex flex-wrap justify-content-center justify-content-md-start">
                                <div className="col-12 col-lg-4 px-0 px-lg-2 ps-0 mb-0 mb-lg-0">
                                    <ReactSelect
                                        placeholder={"Select disease"}
                                        value={speciality}
                                        options={specialities}
                                        getOptionLabel={(options) => options.name}
                                        getOptionValue={(options) => options._id}
                                        onChange={(e) => {
                                            setSpeciality(e)
                                            handleOnChange({ target: { name: "specialities", value: e._id } });
                                        }}
                                        className="react-select-container-4 border_boottom"
                                        classNamePrefix="react-select"
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                boxShadow: state.isHover ? 'transparent' : 'transparent',
                                                boxShadow: state.isFocused ? 'transparent' : 'transparent',
                                                borderColor: state.isFocused ? 'transparent' : 'transparent',
                                                borderColor: state.className ? 'test' : 'test-2',

                                            }),
                                        }}
                                    />
                                </div>
                                <div className="col-12 col-lg-4 px-0 px-lg-2 mb-0 mb-lg-0">
                                    <ReactSelect
                                        placeholder={"Select state"}
                                        value={state}
                                        options={stateData}
                                        getOptionLabel={(options) => options.name}
                                        getOptionValue={(options) => options.name}
                                        onChange={(e) => {
                                            setState(e);
                                            handleState(e);
                                            handleOnChange({ target: { name: "state", value: e?._id } });
                                        }}
                                        className="react-select-container-4 border_boottom"
                                        classNamePrefix="react-select"
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                boxShadow: state.isHover ? 'transparent' : 'transparent',
                                                boxShadow: state.isFocused ? 'transparent' : 'transparent',
                                                borderColor: state.isFocused ? 'transparent' : 'transparent',
                                                borderColor: state.className ? 'test' : 'test-2',

                                            }),
                                        }}
                                    />
                                </div>
                                <div className="col-12 col-lg-4 px-0 px-lg-2 mb-0 mb-lg-0">
                                    <ReactSelect
                                        placeholder={"Select city"}
                                        value={city}
                                        options={cityData}
                                        getOptionLabel={(options) => options.name}
                                        getOptionValue={(options) => options.name}
                                        onChange={(e) => {
                                            setCity(e);
                                            handleOnChange({ target: { name: "city", value: e.name } });
                                        }}
                                        className="react-select-container-4"
                                        classNamePrefix="react-select"
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                boxShadow: state.isHover ? 'transparent' : 'transparent',
                                                boxShadow: state.isFocused ? 'transparent' : 'transparent',
                                                borderColor: state.isFocused ? 'transparent' : 'transparent',
                                                borderColor: state.className ? 'test' : 'test-2',

                                            }),
                                        }} />
                                </div>
                            </div>
                            <div className=" col-xl-1 ">
                                <div className="button_sub mb-3 mb-lg-0 d-flex justify-content-end">
                                    <button className="search_btn position-absolute" onClick={handleSearch}>
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Search;
