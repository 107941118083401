import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { BrowserRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "jquery/dist/jquery.min.js";
import Footer from "./Component/Footer/Footer";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <div id="root" className="d-flex flex-column min-vh-100">
        <div className="content">
          <App />
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  </React.StrictMode>,
);

reportWebVitals();
