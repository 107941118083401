import React from "react";
import Search from "../Component/Search/Search";
import Inquiry from "../Component/Inquiry/Inquiry";
import Foundation from "../Component/Foundation/Foundation";
import Banner from "../Component/Banner/Banner";
import Modal from "../Component/Moal";

const Home = () => {
  return (
    <>
      <Banner />
      <Search />
      <Inquiry />
      <Foundation />
      {/* <Modal /> */}
    </>
  );
};

export default Home;
