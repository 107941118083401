import React from 'react';
import thumb from '../../Assets/pngwing.com (2).png';
import './Verify.css'

const VerifyEmail = () => {
    return (
        <section className='sy-80 mt-5 verify'>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-lg-4 d-flex '>
                        {/* <div className='icon text-center'>
                            <img src={thumb} alt='thumbs-up' />
                            <h3>Your email Id is Verified Now </h3>
                        </div> */}
                        <div class="card text-center"> 
                            <img src={thumb} alt='thumbs-up' class="w-25" />
                                <div class="card-body">
                                    <h5 class="card-title">Your email is verified now!</h5>
                                    <p class="card-text">Unlock exclusive benefits and enjoy a secure browsing experience.</p>
                                    <a href="/" class="btn btn-primary">Go to HomePage</a>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default VerifyEmail;
