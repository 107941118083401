import React from "react";
import "./forgot.css";
import { useForm } from "react-hook-form";
import { BASE_URL } from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const INIT_FORM = {
  password: "",
  confirmPassword: "",
};

const ForgotPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: INIT_FORM,
  });

  const watchAllFields = watch();
  console.log(watchAllFields);

  const onHandleSubmit = async (data) => {
    // setLoading(true)
    const response = await fetch(
      BASE_URL +
        `api/auth/resetpassword/${
          location?.pathname?.split("password/reset/")?.[1] || ""
        }`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: data?.password,
          confirmPassword: data?.confirmPassword,
        }),
      }
    );
    console.log(response);
    if (response.ok) {
      toast.success("Password reset successful");
      reset();
      navigate("/");
      // setLoading(false);
    } else {
      reset();
      // setLoading(false);
      toast.error(data?.message);
      console.error("Register failed:", data);
    }
    const responseData = await response.json();
  };

  return (
    <section className="forget_section">
      <div className="container">
        <div className="row">
          <div className="forget_main_menu col-12 d-flex justify-content-center">
            <form
              className="col-12 col-md-6 col-lg-4"
              onSubmit={handleSubmit(onHandleSubmit)}
            >
              <div className="forget_password">
                <div className="forget_box p-4">
                  <div className="forget_sub_box mb-3">
                    <label htmlFor="" className="mb-2">
                      New Password
                    </label>
                    <br />
                    <input
                      {...register("password", { required: true })}
                      type="text"
                      placeholder="New Password"
                      className="rounded-3"
                    />
                    {errors.password && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                  <div className="forget_sub_box">
                    <label htmlFor="" className="mb-2">
                      Confrom Password
                    </label>
                    <br />
                    <input
                      {...register("confirmPassword", {
                        required: true,
                        validate: (value) =>
                          value === watchAllFields.password ||
                          "Passwords do not match",
                      })}
                      type="text"
                      placeholder="Confrom Password"
                      className="rounded-3"
                    />
                    {errors.confirmPassword && (
                      <p className="text-danger">
                        {errors.confirmPassword.message ||
                          "This field is required"}
                      </p>
                    )}
                  </div>
                  <div className="forget_button mt-3">
                    <button className="text-light rounded-2">Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
