import "./Search_Page.css";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../utils";
import Vector from "../../Assets/Vector.png";
import { City } from "country-state-city";

const PARAMS = {
  specialities: "",
  city: "",
  state: "",
  gender: "",
}

const getQueryParams = (query) => {
  return query
    .replace("?", "")
    .split("&")
    .reduce((acc, param) => {
      const [key, value] = param.split("=");
      acc[key] = value;
      return acc;
    }, {});
};

const SearchPage = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const params = getQueryParams(location.search);

  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(true)
  const [filterParams, setFilterParams] = useState(PARAMS);
  const [filterList, setFilterList] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);

  useEffect(() => {
    setFilterParams({
      specialities: params.specialities || "",
      city: params.city || "",
      state: params.state || "",
      gender: params.gender || "",
    });
    fetchData(params);
  }, [location.search]);

  useEffect(() => {
    const stateName = stateData?.find((v) => v._id === params.state)
    let filteredData = [];
    const data = City.getAllCities();
    const uniqueCities = new Set();

    for (let item of data) {
      if (
        stateName?.countryCode === item?.countryCode &&
        stateName?.isoCode === item?.stateCode
      ) {
        const cityName = item.name.replace(/,/g, "").trim();
        if (!uniqueCities.has(cityName)) {
          uniqueCities.add(cityName);
          filteredData.push({ ...item, name: cityName });
        }
      }
    }
    setCityData(filteredData);
  }, [stateData, filterParams])

  const fetchData = (params) => {
    axios
      .get(BASE_URL + `api/auth/users/filter`, { params })
      .then((res) => {
        setFilterList(res?.data?.users || []);
      })
      .catch((err) => console.log(err));
    axios
      .get(BASE_URL + "api/speciality")
      .then((res) => {
        setSpecialities(res?.data?.specialities || []);
      })
      .catch((err) => console.log(err));
    axios
      .get(BASE_URL + "api/state")
      .then((res) => {
        setStateData(res?.data?.states || []);
      })
      .catch((err) => console.log(err));
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const applyFilter = () => {
    // setIsFilterMenuOpen(false)
    const queryParams = new URLSearchParams(filterParams).toString();
    navigate(`/search?${queryParams}`);
    fetchData(filterParams);
  };

  return (
    <section className="filter" style={{ minHeight: "73.3vh" }}>
      <div className="container ">
        <div className="row">
          <div className="d-flex flex-wrap col-12 align-items-start">
            <div className="col-12 col-lg-2 p-3 filter_box">
              <div className="filter_title border-bottom d-flex align-items-center ">
                <h5>Filter</h5>
                <img src={Vector} className="filter_img ms-auto" alt="" onClick={() => setIsFilterMenuOpen(prev => !prev)} />
              </div>
              {isFilterMenuOpen && <> <div className="col-12 mt-2">
                <select
                  name="specialities"
                  value={filterParams.specialities}
                  onChange={handleFilterChange}
                  className="form-select mb-2"
                  aria-label="Default select example"
                >
                  {specialities?.map((v) => (
                    <option key={v?._id} value={v?._id}>
                      {v?.name}
                    </option>
                  ))}
                </select>
                <select
                  name="state"
                  value={filterParams.state}
                  onChange={handleFilterChange}
                  className="form-select mb-2"
                  aria-label="Default select example"
                >
                  {stateData?.map((v) => (
                    <option key={v?._id} value={v?._id}>
                      {v?.name}
                    </option>
                  ))}
                </select>
                <select
                  name="city"
                  value={filterParams.city}
                  onChange={handleFilterChange}
                  className="form-select mb-2"
                  aria-label="Default select example"
                >
                  {cityData?.map((v) => (
                    <option key={v?._id} value={v?._id}>
                      {v?.name}
                    </option>
                  ))}
                </select>
              </div>
                <div className="filter_title">
                  <div className=" d-flex align-items-center mt-3 border-bottom">
                    <h5>Gender</h5>
                  </div>
                  <div className="d-flex flex-wrap my-2">
                    <select
                      name="gender"
                      value={filterParams.gender}
                      onChange={handleFilterChange}
                      className="form-select"
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>

                </div>
                <button onClick={applyFilter} className="filter_btn my-3">
                  Apply Filter
                </button>
              </>}
            </div>
            <div className="col-12 col-lg-10 px-3 d-flex flex-wrap justify-content-center justify-content-start">
              {filterList?.length > 0
                ? filterList?.map((v) => {
                  const spec = v?.specialities?.find((s) => s?._id === params?.specialities)
                  return <a
                    key={v?._id}
                    href={`/user/${v?._id}`}
                    className=" profile_card col-10 col-lg-3 mt-1"
                  >
                    <div class="card me-0 me-lg-4 mb-3">
                      <img
                        src={v?.avatar}
                        className="card-img-top profile_card_img logo_img ms-auto"
                        alt=""
                      />
                      <div class="card-body">
                        <h6 class="card-title">Name :- {v?.name}</h6>
                        <h6 class="card-title">
                          Degree :- {v?.qualification}
                        </h6>
                        <h6 class="card-title">
                          Experience :- {v?.experience} Year{" "}
                        </h6>
                        <h6 class="card-title">Speciality :- {spec?.name}</h6>
                        <h6 class="card-title">
                          Location :- {v?.city}
                        </h6>
                      </div>
                    </div>
                  </a>
                })
                : "No result found"}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SearchPage;
