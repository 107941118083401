import React, { useEffect, useState } from "react";
import "./Search_Profile.css";
import { BASE_URL } from "../../utils";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

const SearchProfile = () => {

  const location = useLocation();

  const [filterList, setFilterList] = useState({});

  useEffect(() => {
    axios
      .get(BASE_URL + `api/auth/users/${location.pathname.split("/user/")[1]}`)
      .then((res) => {
        const response = res?.data?.user
        const newObject = {};
        delete Object.assign(newObject, response, { ['locmap']: response['map'] })['map'];
        setFilterList(newObject || {});
      })
      .catch((err) => console.log(err));
  }, [location.pathname]);

  return (
    <section className="sy-80">
      <div className="container mt-5">
        <div className="row">
          <div className="d-flex flex-wrap align-items-center">
            <div className="col-12 col-lg-6 d-flex flex-wrap">
              <div class="profile_img col-12 col-lg-6">
                <img
                  src={filterList?.avatar}
                  className="card-img-top ms-auto"
                  alt=""
                />
              </div>
              <div class="profile_text col-12 col-lg-6 ps-0 ps-lg-5 pt-5 pt-lg-0">
                <h5 class="card-title pb-4">Name :- {filterList?.name}</h5>
                <h5 class="card-title pb-4">
                  Degree :- {filterList?.qualification}
                </h5>
                <h5 class="card-title pb-4">
                  Experience :- {filterList?.experience} Year{" "}
                </h5>
                <h5 class="card-title pb-4">Gender :- {filterList?.gender?.charAt(0).toUpperCase() + filterList?.gender?.slice(1)}</h5>
                <h5 class="card-title pb-4">
                  Location :- {filterList?.city}
                </h5>
              </div>
            </div>
            {
              filterList?.locmap?.split("<iframe")?.length > 1 && <div className="col-12 col-lg-6 profile_map">
                <div
                  contentEditable="true"
                  dangerouslySetInnerHTML={{ __html: filterList?.locmap }}
                ></div>
              </div>
            }
          </div>
        </div>
        <div className="row">
          <div className="col-12 pt-5 d-flex flex-wrap">
            <div className="col-12 col-lg-6 pe-0 pe-lg-3">
              <div className="profile_details p-4">
                <h5 class="card-title pb-5">
                  Qualification :- {filterList?.qualification}
                </h5>
                <h5 class="card-title pb-5">
                Specialities :-{" "}
                  {filterList?.specialities?.map((v) => v.name).join(", ")}
                </h5>
                {/* <h5 class="card-title pb-5">
                  Collage /University :- {filterList?.university}{" "}
                </h5> */}
                <h5 class="card-title pb-5">
                  Registration Number :- {filterList?.registration}{" "}
                </h5>
                <h5 class="card-title">
                  Hospital Name :- {filterList?.hospital}
                </h5>
              </div>
            </div>
            <div className="col-12 col-lg-6 pe-0 ps-lg-3">
              <div className="profile_details p-4">
                <h5 class="card-title pb-5">
                  Address :- {filterList?.address}
                </h5>
                <h5 class="card-title pb-5">
                  City :- {filterList?.city}
                </h5>
                <h5 class="card-title pb-5">
                  Phone Number :- {`${filterList?.phone ? filterList?.phone : ''} ${filterList?.phone2 ? ', ' + filterList?.phone2 : ''}`}
                </h5>
                <h5 class="card-title">
                  Website Link :- <Link to={filterList?.website?.split("https://")?.length > 1 ? filterList?.website : `https://${filterList?.website}`} target="_blank">{filterList?.website}</Link>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SearchProfile;
