import React from "react";
import './Contact.css'
import phone_call from '../../Assets/phone-call.png'
import Conatct from '../../Assets/37943 1.png'
import mail from '../../Assets/mail.png'

const Contact = () => {
  return (
    <section>
        <div className="container contact">
            <div className="row">
                <div className='col-12 d-flex flex-wrap align-items-center'>
                    <div className="col-12 col-lg-6">
                        <div className="cont_info">
                            <button className='d-flex justify-content-center align-items-center py-4'>
                                <img src={phone_call} className="img-fluid" alt="" />
                                <p className='mb-0 ms-2 text-white'>+91 8200-845977</p>
                            </button>
                            <button className='d-flex mt-5 justify-content-center align-items-center py-4'>
                                <img src={mail} className="img-fluid" alt="" />
                                <p className='mb-0 ms-2 text-white'>parthvaghani210@gmail.com</p>
                            </button>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                    <img src={Conatct} className="img-fluid mx-2" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Contact;
