import React, { useEffect } from 'react';
import './Banner.css';
import Banner_img from '../../Assets/19.jpg';
import WOW from 'wowjs';
import 'animate.css/animate.css';

const Banner = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <section className="b-img st-80 mt-2 mt-lg-5">
      <div className='container'>
        <div className="col-12 d-flex flex-wrap banner align-items-center">
          {/* <div className="col-12 col-lg-6 wow animate__animated animate__fadeInDown">
            <h1 className="display-5 mb-4 mt-4 fw-bold">Search engine for authentic Ayurvedic physicians</h1>
          </div>
          <div className="col-12 col-lg-6">
            <img src={Banner_img} className="wow animate__animated animate__fadeInDown img-fluid" alt="" />
          </div>  */}
        </div>
      </div>
    </section>
  );
}

export default Banner;
