import React, { useState } from "react";
import "./Header.css";
import Logo from "../../Assets/Logo.png";
import USER_IMG from "../../Assets/usersvg.svg";
import { Link, useLocation } from "react-router-dom";

const navigation = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "About Us",
    path: "/about",
  },
  // {
  //   name: "Contact",
  //   path: "/contact",
  // },
];

const Header = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="fixed-top header_bg">
      <div className="container">
        <header className="d-block">
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link to={"/"}>
              <img src={Logo} className="logo_img" alt="Logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleNavbar}
              aria-controls="navbarNav"
              aria-expanded={isOpen ? "true" : "false"}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse justify-content-end ${isOpen ? "show" : ""}`}
              id="navbarNav"
            >
              <ul className="navbar-nav my-2">
                {navigation.map((v, i) => (
                  <li className="nav-item " key={i}>
                    <Link
                      to={v.path}
                      className={`nav-link ${location.pathname === v.path ? "active" : ""} px-2 px-lg-4 `}
                      onClick={() => setIsOpen(false)}
                    >
                      {v.name}
                    </Link>
                  </li>
                ))}
                <li className="nav-item ps-2 ps-lg-4">
                  {
                    localStorage.getItem('Token') ?
                      <Link
                        className="btn"
                        to="/profile"
                        tabIndex="-1"
                        aria-disabled="true"
                      >
                        <img src={USER_IMG} alt="user" />
                      </Link> :
                      <Link
                        className="btn btn-secondary btn-md"
                        to="/register"
                        tabIndex="-1"
                        aria-disabled="true"
                        onClick={() => setIsOpen(false)}
                      >
                        Signup For Vaidya
                      </Link>
                  }
                </li>
              </ul>
            </div>
          </nav>
        </header>
      </div>
    </div>
  );
};

export default Header;
