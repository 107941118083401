import React from "react";
import "./Foundation.css";
import Jogi from "../../Assets/JOGI.png";
const Foundation = () => {
  return (
    <section className="sy-80 pt-5">
      <div className="container">
        <div className="row">
          <div className="d-flex flex-wrap align-items-center">
            <div className="Foundation-content col-12 col-md-6 text-center mb-5 mb-md-0">
              <h4>An Initiative By</h4>
              <h2>JOGI Foundation</h2>
            </div>
            <div className="Foundation-img col-12 col-md-6 text-center">
              <img src={Jogi} className="logo_img m-auto" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Foundation;
