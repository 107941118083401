import React, { useState, useEffect } from "react";
import "./registration.css";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import thumb from '../../Assets/pngwing.com (2).png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { City } from 'country-state-city';
import { BASE_URL } from "../../utils";
import ReactSelect from "react-select";

const INIT_LOGIN = {
  identifier: "",
  password: "",
}

const STEP = [
  {
    name: "Step 1"
  },
  {
    name: "Step 2"
  },
  {
    name: "Step 3"
  }
]

const INIT_FORM = {
  name: "",
  password: "",
  qualification: "",
  experience: "",
  registration: "",
  specialities: [],
  university: "",
  hospital: "",
  age: "",
  gender: "",
  city: "",
  state: "",
  address: "",
  map: "",
  phone: "",
  phone2: "",
  email: "",
  website: "",
  avatar: null,
  certificate: null,
  nabh: null,
}

const RegistrationForm = () => {

  const navigate = useNavigate();

  const [specialities, setSpecialities] = useState([]);
  const [isLogin, setIsLogin] = useState(true);
  const [loginData, setLoginData] = useState(INIT_LOGIN);
  const [registerData, setRegisterData] = useState(INIT_FORM);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [gender, setGender] = useState("");
  const [qualification, setQualification] = useState("");
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [steps, setSteps] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({})
  const [showPassword, setShowPassword] = useState(false);
  const [showConfiPassword, setShowConfiPassword] = useState(false);
  const [showRegiPassword, setShowRegiPassword] = useState(false);
  const [email, setEmail] = useState("");

  const [open, setOpen] = useState(false)

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false)
    setUserData({})
    navigate('/')
  };

  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm({
    defaultValues: INIT_FORM
  });

  const phoneValue = watch("phone");
  const emailValue = watch("email");

  const watchAllFields = watch();

  const handleNextStep = () => {
    let hasError = false;

    if (steps === 0) {
      if (!registerData.avatar) {
        toast.error("Avatar is required");
        hasError = true;
      }
      if (!watchAllFields.name) {
        toast.error("Name is required");
        hasError = true;
      }
      if (!gender) {
        toast.error("Gender is required");
        hasError = true;
      }
      if (!watchAllFields.age) {
        toast.error("Age is required");
        hasError = true;
      }
      if (!watchAllFields.email) {
        toast.error("Email is required");
        hasError = true;
      }
      if (!watchAllFields.phone) {
        toast.error("Phone number is required");
        hasError = true;
      }
      if (!state) {
        toast.error("State is required");
        hasError = true;
      }
      if (!city) {
        toast.error("City is required");
        hasError = true;
      }
      if (!watchAllFields.address) {
        toast.error("Address is required");
        hasError = true;
      }
    } else if (steps === 1) {
      if (!watchAllFields.registration) {
        toast.error("Registration number is required");
        hasError = true;
      }
      if (!qualification) {
        toast.error("Qualification is required");
        hasError = true;
      }
      if (speciality.length === 0) {
        toast.error("At least one speciality is required");
        hasError = true;
      }
      if (!watchAllFields.university) {
        toast.error("University name is required");
        hasError = true;
      }
      if (!watchAllFields.experience) {
        toast.error("Experience is required");
        hasError = true;
      }
      if (!registerData.certificate) {
        toast.error("Certificate is required");
        hasError = true;
      }
    }

    if (!hasError) {
      setSteps(steps + 1);
    }
  };

  const handlePrevStep = () => {
    setSteps(steps - 1);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const form = {
      ...data,
      state: state?._id,
      city: city?.name,
      specialities: speciality?.map((v) => v?._id),
      gender: gender?.value,
      qualification: qualification?.name,
      avatar: registerData?.avatar,
      certificate: registerData?.certificate,
      nabh: registerData?.nabh,
    };
    try {
      const formData = new FormData();
      for (const key in form) {
        formData.append(key, form[key]);
      }
      const response = await fetch(
        BASE_URL + "api/auth/register",
        {
          method: "POST",
          body: formData,
        },
      );
      const data = await response.json();
      if (response.ok) {
        setUserData(data.user)
        // toast.success("Register successful");
        onOpenModal();
        setRegisterData(INIT_FORM);
        setLoading(false);
      } else {
        reset();
        setSteps(0);
        setLoading(false);
        // toast.error(data?.message);
        console.error("Register failed:", data);
      }
    } catch (error) {
      setSteps(0);
      setLoading(false);
      // toast.error(data?.message);
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await fetch(
          BASE_URL + "api/state",
        );
        const data = await response.json();

        if (data.success) {
          setStateData(data.states);
        } else {
          console.error("Failed to fetch states");
        }
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    const fetchSpeciality = async () => {
      try {
        const response = await fetch(
          BASE_URL + "api/speciality",
        );
        const data = await response.json();

        if (data.success) {
          setSpecialities(data.specialities);
        } else {
          console.error("Failed to fetch specialities");
        }
      } catch (error) {
        console.error("Error fetching specialities:", error);
      }
    };
    fetchSpeciality();
    fetchCities();
  }, []);

  const handleState = (value) => {
    let filteredData = [];
    const data = City.getAllCities();
    for (let item of data) {
      if (
        value?.countryCode === item?.countryCode &&
        value?.isoCode === item?.stateCode
      ) {
        filteredData.push(item);
      }
    }
    setCityData(filteredData);
  };

  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    setRegisterData({
      ...registerData,
      [name]: value,
    });
  };

  const handleLoginChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileInputChange = (event) => {
    const { name, files } = event.target;
    setRegisterData({
      ...registerData,
      [name]: files[0],
    });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      for (const key in registerData) {
        formData.append(key, registerData[key]);
      }
      const response = await fetch(
        BASE_URL + "api/auth/register",
        {
          method: "POST",
          body: formData,
        },
      );
      const data = await response.json();
      if (response.ok) {
        toast.success("Register successful");
        localStorage.setItem("Token", data.token);
        setRegisterData(INIT_FORM);
        console.log("Register successful:");
        navigate("/");
      } else {
        toast.error("Registration failed");
        console.error("Register failed:", data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLoginSubmit = async (e) => {
    console.log("e")
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(
        BASE_URL + "api/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginData),
        },
      );

      const data = await response.json();
      if (response.ok) {
        setLoading(false);
        localStorage.setItem("Token", data.token);
        localStorage.setItem("UserId", data.user);
        setLoginData(INIT_LOGIN);
        navigate("/");
        // toast.success("Login successful");
      } else {
        setLoading(false);
        toast.error(data?.message);
        console.error("Login failed:", data);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Login failed");
      console.error("Error:", error);
    }
  };

  return (
    <section className="y-spacing" style={{ minHeight: "77.2vh" }}>
      <div className="container login">
        <div className="row justify-content-center">
          <button
            className={`login-button ${isLogin ? "active-bar" : "non-active-bar"} col-3`}
            onClick={() => setIsLogin(true)}
          >
            Login
          </button>
          <button
            className={`sign-button ${!isLogin ? "active-bar" : "non-active-bar"} col-3`}
            onClick={() => setIsLogin(false)}
          >
            SignUp
          </button>
        </div>
        {
          loading ? (
            <div className="loader"></div>
          ) : isLogin ? (
            <div className="login_form p-4 my-5 mx-auto col-12 col-md-4">
              <form onSubmit={handleLoginSubmit}>
                <div className="mb-3">
                  <label htmlFor="identifier" className="form-label">
                    Mobile Number / Email ID
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="identifier"
                    name="identifier"
                    placeholder="Mobile Number / Email ID"
                    value={loginData.identifier}
                    onChange={handleLoginChange}
                  />
                </div>
                <div className="mb-3 position-relative">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <div className="input-group">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="form-control"
                      id="password"
                      name="password"
                      placeholder="Password"
                      value={loginData.password}
                      onChange={handleLoginChange}
                    />
                    <span
                      className="input-group-text"
                      onClick={toggleShowPassword}
                      style={{ cursor: 'pointer' }}
                    >
                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="form-check">
                    {/* <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                      Remember Me
                    </label> */}
                  </div>
                  <div className="form-check forget">
                    <Link to="/forgot-password">Forgot Password?</Link>
                  </div>
                </div>
                <div className="d-flex pt-3">
                  <button type="submit" className="action_btn">Submit</button>
                </div>
              </form>
            </div>
          ) : (
            <>
              <section class="py-100 text-dark">
                <div class="container">
                  <div class="row">
                    <div class="wizard">
                      <ul class="nav nav-tabs justify-content-center" role="tablist">
                        {
                          STEP.map((v, i) => {
                            return <li role="presentation" class={`${steps === i ? 'active' : 'disabled'} ${i < steps ? 'active' : ''} col-4 col-lg-3 text-center`}>
                              <a href="#step1" >
                                <span class="round-tab">{i + 1}</span>
                                <i>{v.name}</i>
                              </a>
                            </li>
                          })
                        }
                      </ul>
                    </div>

                  </div>
                </div>
              </section>
              <form className="login_form p-4 mb-5 mx-auto col-12 col-md-8" onSubmit={handleSubmit(onSubmit)}>
                <div className={`${steps > 0 ? "d-none" : ""}`}>
                  <div className="row">
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label">Upload your photo* (.jpg, .jpeg, .png)</label>
                      <input
                        type="file"
                        class="form-control"
                        id="exampleFormControlInput1"
                        accept=".jpg,.jpeg,.png"
                        name="avatar"
                        onChange={handleFileInputChange}
                        required
                      />
                      {errors.avatar && <p className="text-danger">This field is required</p>}
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label">Name*</label>
                      <input type="text" className="form-control" placeholder="Enter Your Name.." {...register("name", { required: true, minLength: 2 })} />
                      {errors.name && <p className="text-danger">This field is required and should be at least 2 characters long</p>}
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label">Gender*</label>
                      <ReactSelect
                        placeholder={"Select your gender"}
                        value={gender}
                        options={[
                          {
                            name: "Male",
                            value: "male"
                          },
                          {
                            name: "Female",
                            value: "female"
                          },
                          {
                            name: "Other",
                            value: "other"
                          },
                        ]}
                        getOptionLabel={(options) => options.name}
                        getOptionValue={(options) => options.value}
                        required
                        onChange={(e) => {
                          setGender(e)
                          handleRegisterChange({ target: { name: "gender", value: e.value } });
                        }}
                        style={{ backgroundColor: "#64a248" }}
                      />
                      {errors.gender && <p className="text-danger">This field is required</p>}
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label">Age*</label>
                      <input type="number" className="form-control" placeholder="Enter Your Age" {...register("age", { required: true })} />
                      {errors.age && <p className="text-danger">This field is required</p>}
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label">Email Id*</label>
                      <input
                        type="email"
                        className="form-control lowercase-input"
                        placeholder="Verification mail will be sent on your mail id"
                        {...register("email", {
                          required: "This field is required",
                          pattern: {
                            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                            message: "Invalid email address"
                          },
                        })}
                      />
                      {errors.email && (
                        <p className="text-danger">
                          {errors.email.type === "required" && "This field is required"}
                          {errors.email.type === "pattern" && errors.email.message}
                        </p>
                      )}
                      {!errors.email && emailValue && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(emailValue) && (
                        <p className="text-danger">Invalid email address</p>
                      )}
                    </div>
                    <div className="mb-3 col-12 col-md-3">
                      <label className="form-label">Phone No.1*</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Phone No.."
                        {...register("phone", {
                          required: "This field is required",
                          pattern: {
                            value: /^[6-9]\d{9}$/,
                            message: "Invalid phone number, must be 10 digits and start with 6-9"
                          }
                        })}
                      />
                      {errors.phone && (
                        <p className="text-danger">
                          {errors.phone.type === "required" && "This field is required"}
                          {errors.phone.type === "pattern" && errors.phone.message}
                        </p>
                      )}
                      {!errors.phone && phoneValue && !/^[6-9]\d{9}$/.test(phoneValue) && (
                        <p className="text-danger">Invalid phone number or must be 10 digits</p>
                      )}
                    </div>
                    <div className="mb-3 col-12 col-md-3">
                      <label className="form-label">Phone No.2</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Phone No.."
                        {...register("phone2", {
                          // required: "This field is required",
                          pattern: {
                            value: /^[6-9]\d{9}$/,
                            message: "Invalid phone number, must be 10 digits and start with 6-9"
                          }
                        })}
                      />
                      {errors.phone2 && (
                        <p className="text-danger">
                          {errors.phone2.type === "required" && "This field is required"}
                          {errors.phone2.type === "pattern" && errors.phone2.message}
                        </p>
                      )}
                      {!errors.phone2 && phoneValue && !/^[6-9]\d{9}$/.test(phoneValue) && (
                        <p className="text-danger">Invalid phone number or must be 10 digits</p>
                      )}
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label">State*</label>
                      <ReactSelect
                        className="bgChange"
                        placeholder={"Select your state"}
                        value={state}
                        options={stateData}
                        getOptionLabel={(options) => options.name}
                        getOptionValue={(options) => options.name}
                        required
                        onChange={(e) => {
                          setState(e);
                          handleState(e);
                          handleRegisterChange({ target: { name: "state", value: e?._id } });
                        }}
                      />
                      {errors.state && <p className="text-danger">This field is required</p>}
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label">City*</label>
                      <ReactSelect
                        placeholder={"Select your city"}
                        value={city}
                        options={cityData}
                        getOptionLabel={(options) => options.name}
                        getOptionValue={(options) => options.name}
                        required
                        onChange={(e) => {
                          setCity(e);
                          handleRegisterChange({ target: { name: "city", value: e.name } });
                        }}
                        style={{ backgroundColor: "#64a248" }}
                      />
                      {errors.city && <p className="text-danger">This field is required</p>}
                    </div>
                    <div className="mb-3 col-12">
                      <label className="form-label">Address*</label>
                      <textarea className="form-control" placeholder="Enter Your Address.." {...register("address", { required: true })}></textarea>
                      {errors.address && <p className="text-danger">This field is required</p>}
                    </div>
                    <div className="mb-3 col-12">
                      <div className="d-flex justify-content-center pt-3">
                        <button type="button" className="action_btn" style={{ width: "100%" }} onClick={handleNextStep}>Next</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`${steps < 1 || steps > 1 ? "d-none" : ""}`}>
                  <div className="row">
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label">Registration No.*</label>
                      <input type="text" className="form-control" placeholder="Enter Your Registration No..." {...register("registration", { required: true })} />
                      {errors.registration && <p className="text-danger">This field is required</p>}
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label">Qualification*</label>
                      <ReactSelect
                        placeholder={"Select your qualification"}
                        value={qualification}
                        options={[
                          {
                            name: "BAMS"
                          },
                          {
                            name: "MD/MS"
                          },
                          {
                            name: "PhD"
                          },
                          // {
                          //   name: "DANP"
                          // },
                          // {
                          //   name: "Certificate Courses"
                          // },
                        ]}
                        getOptionLabel={(options) => options.name}
                        getOptionValue={(options) => options.name}
                        required
                        onChange={(e) => {
                          setQualification(e)
                          handleRegisterChange({ target: { name: "qualification", value: e.name } });
                        }}
                        style={{ backgroundColor: "#64a248" }}
                      />
                      {errors.qualification && <p className="text-danger">This field is required</p>}
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label">Speciality*</label>
                      <ReactSelect
                        isMulti
                        placeholder={"Select your specialities"}
                        value={speciality}
                        options={specialities}
                        getOptionLabel={(options) => options.name}
                        getOptionValue={(options) => options._id}
                        required
                        onChange={(e) => {
                          setSpeciality(e)
                        }}
                        style={{ backgroundColor: "#64a248" }}
                      />
                      {errors.speciality && <p className="text-danger">This field is required</p>}
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label">College/University*</label>
                      <input type="text" className="form-control" placeholder="Enter Your College/University Name" {...register("university", { required: true })} />
                      {errors.university && <p className="text-danger">This field is required</p>}
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label">Hospital Name</label>
                      <input type="text" className="form-control" placeholder="Enter Your Hospital Name" {...register("hospital")} />
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label">Experience*</label>
                      <input type="number" className="form-control" placeholder="Enter Your Year of Experience..." {...register("experience", { required: true })} />
                      {errors.experience && <p className="text-danger">This field is required</p>}
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label">Your location</label>
                      <input type="text" className="form-control" placeholder="Enter your ebaded location link" {...register("map")} />
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label">Website Link</label>
                      <input type="text" className="form-control" placeholder="Enter Your Website Link" {...register("website")} />
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label">Degree Certificate* (.jpg, .jpeg, .png)</label>
                      <input
                        type="file"
                        class="form-control"
                        id="exampleFormControlInput1"
                        accept=".jpg,.jpeg,.png"
                        name="certificate"
                        onChange={handleFileInputChange}
                        required
                      />
                      {errors.certificate && <p className="text-danger">This field is required</p>}
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label">NABH Certificate (.jpg, .jpeg, .png)</label>
                      <input
                        type="file"
                        class="form-control"
                        id="exampleFormControlInput1"
                        name="nabh"
                        accept=".jpg,.jpeg,.png"
                        onChange={handleFileInputChange}
                      />
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <div className="d-flex justify-content-center pt-3">
                        <button type="button" className="back_btn" style={{ width: "100%" }} onClick={handlePrevStep}>Back</button>
                      </div>
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <div className="d-flex justify-content-center pt-3">
                        <button type="button" className="action_btn" style={{ width: "100%" }} onClick={handleNextStep}>Next</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`${steps < 2 ? "d-none" : ""}`}>
                  <div className="row">
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label">Password*</label>
                      <div className="input-group">
                        <input
                          type={showRegiPassword ? 'text' : 'password'}
                          className="form-control"
                          placeholder="Password"
                          {...register("password", {
                            required: "Password is required",
                            minLength: {
                              value: 6,
                              message: "Password must be at least 6 characters"
                            }
                          })}
                        />
                        <span
                          className="input-group-text"
                          onClick={() => setShowRegiPassword(!showRegiPassword)}
                          style={{ cursor: 'pointer' }}
                        >
                          <FontAwesomeIcon icon={showRegiPassword ? faEyeSlash : faEye} />
                        </span>
                      </div>
                      {errors.password && <p className="text-danger">{errors.password.message}</p>}
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label">Confirm Password*</label>
                      <div className="input-group">
                        <input
                          type={showConfiPassword ? 'text' : 'password'}
                          className="form-control"
                          placeholder="Confirm Password"
                          {...register("cpassword", {
                            required: true,
                            validate: (value) => value === watchAllFields.password || "Password does not match"
                          })}
                        />
                        <span
                          className="input-group-text"
                          onClick={() => setShowConfiPassword(!showConfiPassword)}
                          style={{ cursor: 'pointer' }}
                        >
                          <FontAwesomeIcon icon={showConfiPassword ? faEyeSlash : faEye} />
                        </span>
                      </div>
                      {errors.cpassword && <p className="text-danger">{errors.cpassword.message}</p>}
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <div className="d-flex justify-content-center pt-3">
                        <button type="button" className="back_btn" style={{ width: "100%" }} onClick={handlePrevStep}>Back</button>
                      </div>
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <div className="d-flex justify-content-center pt-3">
                        <button type="submit" className="action_btn" style={{ width: "100%" }}>Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          )
        }
      </div>
      {
        // userData?.name &&
        <Modal open={open} onClose={onCloseModal} center>
          <div class="modal-body">x
            <div class="tab-content">
              <div id="" class="tab-pane fade active show ">
                <div class="col-12 aling-item-center">
                  <img src={thumb} alt='thumbs-up' class="d-flex" />
                  <h3>Dear {userData?.name}</h3>
                  <p>Thank you for joining our community at VaidyaNearMe.</p>
                  <p>We are delighted to have you in our network. Your registration details have been received and are currently being processed for approval. The process will take 24 hours. If you need assistance, please contact our support team at  
                  +91 90161 66757 or ✉ support@vaidyanearme.com. </p>
                  <p>We appreciate your interest and look forward to serving you.</p>
                  <button onClick={() => window.open('https://mail.google.com/', '_blank')} className="btn btn-primary">Verify your email</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      }
    </section>
  );
};

export default RegistrationForm;
