import React, { useEffect, useState } from "react";
import "./Edit_Profile.css";
import Profile_1 from "../../Assets/Profile_1.png";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../utils";
import { toast } from "react-toastify";
import ReactSelect from "react-select";

const ProfilePage = () => {
  const navigate = useNavigate();

  const [isEditable, setIsEditable] = useState(false);
  const [specialities, setSpecialities] = useState([]);
  const [getSpecialities, setGetSpecialities] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [imageAvatar, setImageAvatar] = useState(null);
  const [userData, setUserData] = useState({
    avatar: "",
    name: "",
    phone: "",
    email: "",
    experience: "",
    gender: "",
    address: "",
    city: "",
    qualification: "",
    university: "",
    hospital: "",
    website: "",
  });

  const userId = localStorage.getItem("UserId");
  useEffect(() => {
    if (userId) {
      fetch(`${BASE_URL}api/auth/users/${userId}`)
        .then((response) => response.json())
        .then((data) => {
          if (data?.error) {
            localStorage.removeItem("Token");
            localStorage.removeItem("UserId");
            navigate("/");
            return;
          }
          setSpecialities(data?.user?.specialities);
          setImageAvatar(data?.user?.avatar);
          setUserData({
            avatar: data?.user?.avatar,
            name: data?.user?.name,
            phone: data?.user?.phone,
            email: data?.user?.email,
            experience: data?.user?.experience,
            gender: data?.user?.gender,
            address: data?.user?.address,
            city: data?.user?.city,
            qualification: data?.user?.qualification,
            // speciality: data?.user?.specialities,
            university: data?.user?.university,
            hospital: data?.user?.hospital,
            website: data?.user?.website,
          });
        })
        .catch((error) => console.error("Error fetching user data:", error));
    }
    const fetchSpeciality = async () => {
      try {
        const response = await fetch(BASE_URL + "api/speciality");
        const data = await response.json();

        if (data.success) {
          setGetSpecialities(data.specialities);
        } else {
          console.error("Failed to fetch specialities");
        }
      } catch (error) {
        console.error("Error fetching specialities:", error);
      }
    };
    fetchSpeciality();
  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSpecialities = (e) => {
    console.log("e---->>", e);
    setSpecialities(e);
  };

  const handleSubmit = async (data) => {
    const specialityIds = specialities.map((speciality) => speciality._id);

    let form = {
      avatar: userData?.avatar,
      name: userData?.name,
      phone: userData?.phone,
      email: userData?.email,
      experience: userData?.experience,
      gender: userData?.gender,
      address: userData?.address,
      city: userData?.city,
      specialities: specialityIds,
      qualification: userData?.qualification,
      university: userData?.university,
      hospital: userData?.hospital,
      website: userData?.website,
    };

    try {
      if (imageData) {
        const formData = new FormData();
        formData.append("file", imageData);
        const response = await fetch(`${BASE_URL}api/auth/upload/${userId}`, {
          method: "POST",
          body: formData,
        });
        // Handle the response
        const result = await response.json();
        form = {
          ...form,
          avatar: result?.downloadURL
        }
        if (imageAvatar) {
          const response = await fetch(`${BASE_URL}api/auth/image/delete${userId}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ fileUrl: imageAvatar }),
          });
        }
      }
      const response = await fetch(`${BASE_URL}api/request/${userId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      });

      if (response.ok) {
        handleCancelClick();
        toast.success("Update request submitted successfully");
        console.log("Update request submitted successfully");
      } else {
        handleCancelClick();
        toast.error("Your request alredy added");
        console.error(response);
      }
    } catch (error) {
      handleCancelClick();
      toast.error("Error submitting update request");
      console.error("Error submitting update request:", error);
    }
  };

  const handleEditClick = () => {
    setIsEditable(true);
  };

  const handleFileInputChange = (event) => {
    const { name, files } = event.target;
    setImageData(files[0]);
  };

  const handleCancelClick = () => {
    setIsEditable(false);
    setUserData({
      avatar: userData.avatar,
      name: userData.name,
      phone: userData.phone,
      email: userData.email,
      experience: userData.experience,
      gender: userData.gender,
      address: userData.address,
      city: userData.city,
      qualification: userData.qualification,
      speciality: userData.speciality,
      university: userData.university,
      hospital: userData.hospital,
      website: userData.website,
    });
  };

  return (
    <section>
      <div className="container profile-container">
        <div className="row">
          <div className="d-flex justify-content-center">
            <div className="col-12 col-lg-6">
              {!isEditable && (
                <div className="edit_photo col-12 col-lg-4">
                  <img
                    src={userData?.avatar ? userData?.avatar : Profile_1}
                    className="mx-auto img-fluid"
                    alt="Profile"
                  />
                </div>
              )}

              <div className="edit_info my-5 p-4 col-12">
                <div className="mb-3">
                  {isEditable && (
                    <>
                      <label className="form-label">
                        Upload your photo* (.jpg, .jpeg, .png)
                      </label>
                      <input
                        type="file"
                        class="form-control"
                        id="exampleFormControlInput1"
                        accept=".jpg,.jpeg,.png"
                        name="avatar"
                        onChange={handleFileInputChange}
                        required
                      />
                    </>
                  )}
                  <label
                    style={{ fontWeight: "bold" }}
                    htmlFor="name"
                    className="form-label"
                  >
                    Name
                  </label>
                  {isEditable ? (
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={userData.name}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p>{userData.name}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label
                    style={{ fontWeight: "bold" }}
                    htmlFor="phone"
                    className="form-label"
                  >
                    Phone Number
                  </label>
                  {isEditable ? (
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      value={userData.phone}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p>{userData.phone}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label
                    style={{ fontWeight: "bold" }}
                    htmlFor="email"
                    className="form-label"
                  >
                    Email ID
                  </label>
                  {isEditable ? (
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="email"
                      value={userData.email}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p>{userData.email}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label
                    style={{ fontWeight: "bold" }}
                    htmlFor="experience"
                    className="form-label"
                  >
                    Experience
                  </label>
                  {isEditable ? (
                    <input
                      type="text"
                      className="form-control"
                      id="experience"
                      value={userData.experience}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p>{userData.experience}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label
                    style={{ fontWeight: "bold" }}
                    htmlFor="gender"
                    className="form-label"
                  >
                    Gender
                  </label>
                  {isEditable ? (
                    <input
                      type="text"
                      className="form-control"
                      id="gender"
                      value={userData.gender}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p>{userData.gender}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label
                    style={{ fontWeight: "bold" }}
                    htmlFor="address"
                    className="form-label"
                  >
                    Address
                  </label>
                  {isEditable ? (
                    <textarea
                      className="form-control"
                      id="address"
                      value={userData.address}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p>{userData.address}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label
                    style={{ fontWeight: "bold" }}
                    htmlFor="city"
                    className="form-label"
                  >
                    City
                  </label>
                  {isEditable ? (
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      value={userData.city}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p>{userData.city}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label
                    style={{ fontWeight: "bold" }}
                    htmlFor="qualification"
                    className="form-label"
                  >
                    Qualification
                  </label>
                  {isEditable ? (
                    <input
                      type="text"
                      className="form-control"
                      id="qualification"
                      value={userData.qualification}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p>{userData.qualification}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label
                    style={{ fontWeight: "bold" }}
                    htmlFor="speciality"
                    className="form-label"
                  >
                    Speciality
                  </label>
                  {isEditable ? (
                    <ReactSelect
                      isMulti
                      placeholder={"Select your specialities"}
                      defaultValue={specialities}
                      options={getSpecialities}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option._id}
                      onChange={(e) => {
                        handleSpecialities(e);
                      }}
                      className="form-control"
                    />
                  ) : (
                    <ul>
                      {specialities?.map((speciality, index) => (
                        <li key={index}>{speciality?.name}</li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="mb-3">
                  <label
                    style={{ fontWeight: "bold" }}
                    htmlFor="university"
                    className="form-label"
                  >
                    College/University
                  </label>
                  {isEditable ? (
                    <input
                      type="text"
                      className="form-control"
                      id="university"
                      value={userData.university}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p>{userData.university}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label
                    style={{ fontWeight: "bold" }}
                    htmlFor="hospital"
                    className="form-label"
                  >
                    Hospital Name
                  </label>
                  {isEditable ? (
                    <input
                      type="text"
                      className="form-control"
                      id="hospital"
                      value={userData.hospital}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p>{userData.hospital}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label
                    style={{ fontWeight: "bold" }}
                    htmlFor="website"
                    className="form-label"
                  >
                    Website Link
                  </label>
                  {isEditable ? (
                    <input
                      type="text"
                      className="form-control"
                      id="website"
                      value={userData.website}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p>{userData.website}</p>
                  )}
                </div>
                {isEditable ? (
                  <div className="d-flex justify-content-end">
                    <button className="edit_btn" onClick={handleSubmit}>
                      Request for update
                    </button>
                    <button className="cancel_btn" onClick={handleCancelClick}>
                      Cancel
                    </button>
                  </div>
                ) : (
                  <button className="edit_btn" onClick={handleEditClick}>
                    Edit Profile
                  </button>
                )}
                <button
                  className="logout_btn"
                  onClick={() => {
                    localStorage.removeItem("Token");
                    localStorage.removeItem("UserId");
                    navigate("/");
                  }}
                >
                  LOGOUT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfilePage;
