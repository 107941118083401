import React from "react";
import "./Inquiry.css";

const Inquiry = () => {
  return (
    <section className="mt-5 mt-lg-0">
      <div className="container">
        <div className="row">
          <div className="inquiry  col-12 p-2 d-flex flex-wrap align-items-center justify-content-center">
            <div className="col-12 col-lg-4 d-flex justify-content-center justify-content-lg-start">
              <h6 className="inquiry-title mb-0">
                Need any help? Contact to our support team
              </h6>
            </div>
            <div className="col-12 col-lg-4 d-flex flex-wrap justify-content-center ps-3 ">
              <button className=" inquiry_btn border-0 d-flex justify-content-center ">
                <a href="tel:8800-118053" className="d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-telephone-fill pe-2" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                  </svg>
                  +91 8800-118053<span> (8am to 8pm)</span>
                </a>
              </button>
            </div>
            <div className="col-12 col-lg-3 d-flex flex-wrap justify-content-center border border-lg-start-1 border-start-0 border-bottom-0 border-top-0 border-end-0 jogi_mail">
              <button className=" inquiry_btn border-0 d-flex justify-content-center justify-content-lg-start ">
                <a href="mailto:support@vaidyanearme.com" className="d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="512"
                    height="512"
                    viewBox="0 0 512 512"
                    style={{ enableBackground: 'new 0 0 512 512' }}
                    xmlSpace="preserve"
                    className="me-3"
                  >
                    <g>
                      <path
                        d="m331.756 277.251-42.881 43.026c-17.389 17.45-47.985 17.826-65.75 0l-42.883-43.026L26.226 431.767C31.959 434.418 38.28 436 45 436h422c6.72 0 13.039-1.58 18.77-4.232L331.756 277.251z"
                        fill="#ffffff"
                        opacity="1"
                        data-original="#000000"
                        className=""
                      />
                      <path
                        d="M467 76H45c-6.72 0-13.041 1.582-18.772 4.233l164.577 165.123c.011.011.024.013.035.024a.05.05 0 0 1 .013.026l53.513 53.69c5.684 5.684 17.586 5.684 23.27 0l53.502-53.681s.013-.024.024-.035c0 0 .024-.013.035-.024L485.77 80.232C480.039 77.58 473.72 76 467 76zM4.786 101.212C1.82 107.21 0 113.868 0 121v270c0 7.132 1.818 13.79 4.785 19.788l154.283-154.783L4.786 101.212zM507.214 101.21 352.933 256.005 507.214 410.79C510.18 404.792 512 398.134 512 391V121c0-7.134-1.82-13.792-4.786-19.79z"
                        fill="#ffffff"
                        opacity="1"
                        data-original="#000000"
                        className=""
                      />
                    </g>
                  </svg>
                  support@vaidyanearme.com
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Inquiry;
