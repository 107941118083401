// import logo from './logo.svg';
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";

import Header from "./Component/Header/Header";
import Footer from "./Component/Footer/Footer";
import AboutUs from "./Component/About_Us/About_Us";
import Home from "./Pages/home";
import RegistrationForm from "./Pages/Registration/Registration_Form";
import Contact from "./Pages/Contact/Contact";
import SearchPage from "./Pages/Search/Search_Page";
import SearchProfile from "./Pages/UserProfile/Search_Profile";
import ProfilePage from "./Pages/ProfilePage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VerifyEmail from "./Pages/Verify";
import undercontraction from './Assets/ComingSoon.jpg'
import ForgotPassword from "./Pages/ForgotPassword";
import Forgot from "./Pages/ForgotPassword/forgot";

function App() {

  const location = useLocation()

  return (
    <>
      {/* <img src={undercontraction} alt="image"/>       */}
      {/* ForgotPassword */}
      <Header />
      <Routes>
        <Route path="*" element={<>Not found</>} />
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/register" element={<RegistrationForm />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/user/:userId" element={<SearchProfile />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/verify" element={<VerifyEmail />} />
        <Route path="/password/reset/:resettoken" element={<ForgotPassword />} />
        <Route path="/forgot-password" element={<Forgot />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        theme="light"
      />
      <ToastContainer />
    </>
  );
}

export default App;
