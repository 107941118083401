import React from "react";
import "./forgot.css";
import { useForm } from "react-hook-form";
import { BASE_URL } from "../../utils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const INIT_FORM = {
  email: "",
};

const Forgot = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: INIT_FORM,
  });

  const watchAllFields = watch();

  // const onHandleSubmit = async (data) => {
  //     // setLoading(true)
  //     const response = await fetch(
  //         BASE_URL + `api/auth/forgotpassword`,
  //         {
  //             method: "POST",
  //             body: {
  //                 password: data?.password,
  //                 confirmPassword: data?.confirmPassword
  //             },
  //         },
  //     );
  //     const res = await response.json()
  //     console.log(res)
  //     if (res.ok) {
  //         toast.success("Reset email sent on your email!");
  //         reset()
  //         navigate("/")
  //         // setLoading(false);
  //     } else {
  //         console.log("Register failed:", res);
  //         toast.error(res?.message);
  //         reset();
  //         // setLoading(false);
  //     }
  // }

  const onHandleSubmit = async (data) => {
    const response = await fetch(BASE_URL + `api/auth/forgotpassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: data?.email,
      }),
    });
    const res = await response.json();
    console.log(res);
    if (res.status === 200) {
      toast.success("Reset email sent on your email!");
      reset();
    } else {
      console.log("Register failed:", res);
      toast.error(res?.message);
      reset();
    }
  };

  return (
    <section className="forget_section">
      <div className="container">
        <div className="row">
          <div className="forget_main_menu col-12 d-flex justify-content-center">
            <form
              className="col-12 col-md-6 col-lg-4"
              onSubmit={handleSubmit(onHandleSubmit)}
            >
              <div className="forget_password">
                <div className="forget_box p-4">
                  <div className="forget_sub_box mb-3">
                    <label htmlFor="" className="mb-2">
                      Enter your email
                    </label>
                    <br />
                    <input
                      {...register("email", { required: true })}
                      type="text"
                      placeholder="xxx@example.com"
                      className="rounded-3"
                    />
                    {errors.email && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                  <div className="forget_button mt-3">
                    <button className="text-light rounded-2">Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Forgot;
