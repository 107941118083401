import React from "react";
import About_1 from "../../Assets/About_1.png";
import About_2 from "../../Assets/About_2.png";
import AboutLogo from "../../Assets/Logo.png";
import "./About_Us.css";
const AboutUs = () => {
  return (
   <>
    <section className="about-spacing d-none d-lg-block">
      <div className="container mt-5">
        <div className="row mb-4">
          <div className="d-flex flex-wrap">
            <div className="col-12 col-lg-6 ">
              <div className="about-title">
                <h3 className="mb-4 text-center text-lg-start">About VaidyaNearMe</h3>
                <p>
                  We believe that the potential of Ayurved has not been explored
                  the way it should be! Millions of people are looking forward
                  to get authentic Ayurved treatment; but in absence of
                  awareness and information about the practitioner; they resort
                  to other treatments unwillingly.
                </p>

                <p className="mt-4">
                  The sole purpose of this website is to connect the people
                  seeking Ayurved treatment with the authentic practitioner
                  nearby.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <img
                src={AboutLogo}
                className="About_1 img-fluid ps-0 ps-lg-3"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="row sy-80">
          <div className="d-flex flex-wrap">
            <div className="col-12 col-lg-6">
              <img src={About_2} className="About_1 img-fluid " alt="" />
            </div>
            <div className="col-12 col-lg-6 ps-0 ps-lg-3">
              <div className="about-title">
                <h3 className="mb-4 text-center text-lg-start">The Founder</h3>
                <p>
                  Mr. Nilesh Jogal is the founder of JOGI Ayurved Pvt. Ltd. and
                  JOGI Foundation.
                </p>
                <p className="mt-4">
                  JOGI Foundation aims to establish Ayurveda as the first choice
                  of treatment in India and abroad. As a part of this quest;
                  JOGI Foundation has taken this initiative to help people find
                  the RIGHT vaidyas near them.
                </p>

                <p className="mt-4">
                  The aim is to help aspiring ayurved practiceners by connecting
                  them with the people desirous to go for authentic ayurved
                  treatment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="about-spacing d-block d-lg-none">
      <div className="container mt-5">
        <div className="row mb-4">
          <div className="d-flex flex-wrap">
            <div className="col-12 col-lg-6 pb-5 pb-lg-0">
            <h3 className="about_heading mb-4 text-center text-lg-start">About VaidyaNearMe</h3>
              <img
                src={AboutLogo}
                className="About_1 img-fluid"
                alt=""
              />
            </div>
            <div className="col-12 col-lg-6 ps-0 ps-lg-3">
              <div className="about-title">
                <p>
                  We believe that the potential of Ayurved has not been explored
                  the way it should be! Millions of people are looking forward
                  to get authentic Ayurved treatment; but in absence of
                  awareness and information about the practitioner; they resort
                  to other treatments unwillingly.
                </p>

                <p className="mt-4">
                  The sole purpose of this website is to connect the people
                  seeking Ayurved treatment with the authentic practitioner
                  nearby.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-5">
          <div className="d-flex flex-wrap">
            <div className="col-12 col-lg-6 pb-5 pb-lg-0">
            <h3 className="about_heading mb-4 text-center text-lg-start">The Founder</h3>
              <img src={About_2} className="About_1 img-fluid " alt="" />
            </div>
            <div className="col-12 col-lg-6 ps-0 ps-lg-3">
              <div className="about-title">
                <p>
                  Mr. Nilesh Jogal is the founder of JOGI Ayurved Pvt. Ltd. and
                  JOGI Foundation.
                </p>
                <p className="mt-4">
                  JOGI Foundation aims to establish Ayurveda as the first choice
                  of treatment in India and abroad. As a part of this quest;
                  JOGI Foundation has taken this initiative to help people find
                  the RIGHT vaidyas near them.
                </p>

                <p className="mt-4">
                  The aim is to help aspiring ayurved practiceners by connecting
                  them with the people desirous to go for authentic ayurved
                  treatment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
   </>
  );
};

export default AboutUs;
